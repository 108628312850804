var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "offer-ordered row align-items-center" }, [
    _c(
      "div",
      { staticClass: "col-md-6 thank-you-header" },
      [
        _c("info-badge", {
          attrs: { description: _vm.description, title: _vm.title }
        })
      ],
      1
    ),
    _c("div", { staticClass: "col-md-6 d-none d-md-block" }, [
      _c(
        "div",
        { staticClass: "ordered-product mb-3" },
        [
          _c("h3", [_vm._v("Die Bestellung:")]),
          _c("product-bullets", {
            attrs: {
              "bullet-points": _vm.bulletPoints,
              image: _vm.image,
              "optional-mobile": _vm.optionalMobile,
              "always-product-image": ""
            }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }