var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "info-badge mb-4 mt-2" }, [
    _c("div", { staticClass: "row align-items-center" }, [
      _vm._m(0),
      _c("div", { staticClass: "col" }, [
        _c("h4", [
          _c("span", { staticClass: "icon inline" }),
          _vm._v(" " + _vm._s(_vm.title))
        ]),
        _vm.description
          ? _c("p", {
              staticClass: "description",
              domProps: { innerHTML: _vm._s(_vm.description) }
            })
          : _vm._e()
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-2 icon-col d-none d-lg-block" }, [
      _c("span", { staticClass: "icon" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }