














import InfoBadge from '@/components/info-badge.vue';
import ProductBullets from '@/components/product-bullets.vue';
import {Component, Prop} from '@/decorators';
import Vue from 'vue';

@Component({components: {InfoBadge, ProductBullets}})
export default class OfferOrdered extends Vue {
    @Prop
    private title: string;

    @Prop({required: false})
    private description?: string;

    @Prop
    private image: string;

    @Prop
    private bulletPoints: string;

    @Prop({required: false, default: false})
    private optionalMobile?: boolean;
}
