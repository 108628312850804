import { render, staticRenderFns } from "./offer-ordered.vue?vue&type=template&id=ee777fc8&"
import script from "./offer-ordered.vue?vue&type=script&lang=ts&"
export * from "./offer-ordered.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/peter/dev/subscription-portal/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('ee777fc8')) {
      api.createRecord('ee777fc8', component.options)
    } else {
      api.reload('ee777fc8', component.options)
    }
    module.hot.accept("./offer-ordered.vue?vue&type=template&id=ee777fc8&", function () {
      api.rerender('ee777fc8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/offer-ordered.vue"
export default component.exports