














import {Component, Prop} from '@/decorators';
import Vue from 'vue';

@Component({})
export default class InfoBadge extends Vue {
    @Prop
    private title: string;

    @Prop({required: false})
    private description?: string;

}
